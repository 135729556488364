<template>
  <div>
    <template v-if="getComponent(widgetDetails.widgetCategoryID)">
      <component
        :is="getComponent(widgetDetails.widgetCategoryID).component"
        :widgetDetails="widgetDetails"
        :data="widgetDetails[getComponent(widgetDetails.widgetCategoryID).name]"
        :chartConfigurations="widgetDetails.chartConfigurations"
        :widgetTblConfigurations="widgetDetails.widgetTblConfigurations"
        :tenantId="widgetDetails.tenantID"
        :formSubmitted="formSubmitted"
        :isEditPage="isEditPage"
      ></component>
    </template>
    <b-form-row>
      <b-col>
        <b-form-group
          :class="{
            'is-invalid':
              !widgetDetails.contentWidgets.registeredContent &&
              widgetDetails.widgetCategoryID == 1 &&
              formSubmitted,
          }"
        >
          <p>
            Dashboard text
            <span v-if="widgetDetails.widgetCategoryID != 1">(Optional)</span>
          </p>
          <editor
            id="description"
            :api-key="tinyEditorOptions.apiKey"
            :init="tinyEditorOptions.initConfig"
            v-model="widgetDetails.contentWidgets.registeredContent"
          />
          <b-form-invalid-feedback
            class="d-block"
            v-if="
              !widgetDetails.contentWidgets.registeredContent &&
              widgetDetails.widgetCategoryID == 1 &&
              formSubmitted
            "
            >Dashboard content required.</b-form-invalid-feedback
          >
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col>
        <b-form-group>
          <b-form-checkbox
            id="use-dashboard-text-check"
            name="use-dashboard-text-check"
            @change="updateExpandedViewText"
          >
            Use dashboard text for expanded view
          </b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col>
        <b-form-group
          :class="{
            'is-invalid':
              !widgetDetails.contentWidgets.registeredContentExpanded &&
              widgetDetails.widgetCategoryID == 1 &&
              formSubmitted,
          }"
        >
          <p>
            Expanded view text
            <span v-if="widgetDetails.widgetCategoryID != 1">(Optional)</span>
          </p>
          <editor
            :api-key="tinyEditorOptions.apiKey"
            :init="tinyEditorOptions.initConfig"
            v-model="widgetDetails.contentWidgets.registeredContentExpanded"
          />
          <b-form-invalid-feedback
            class="d-block"
            v-if="
              !widgetDetails.contentWidgets.registeredContentExpanded &&
              widgetDetails.widgetCategoryID == 1 &&
              formSubmitted
            "
            >Expanded view content required.</b-form-invalid-feedback
          >
        </b-form-group>
      </b-col>
    </b-form-row>
  </div>
</template>
<script>
import Editor from '@tinymce/tinymce-vue'
import {
  TinyEditorOptions,
  WidgetTypesSettings
} from '../../../utilities/constants'
export default {
  name: 'Content',
  props: ['widgetDetails', 'isEditPage', 'formSubmitted'],
  components: {
    editor: Editor,
    CalendarWidget: () => import('./CalendarWidget.vue'),
    NewsWidget: () => import('./NewsWidget.vue'),
    LocationWidget: () => import('./LocationWidget.vue'),
    IntegrationWidget: () => import('./IntegrationWidget.vue'),
    FormBuilderWidget: () => import('./FormBuilderWidget.vue'),
    RecreationWidget: () => import('./RecreationWidget.vue')
  },
  data () {
    return {
      tinyEditorOptions: TinyEditorOptions,
      widgetTypesSettings: WidgetTypesSettings
    }
  },
  computed: {
    getComponent () {
      return (id) => {
        const widget = this.widgetTypesSettings.find(
          (widget) => widget.widgetCategoryID === id
        )
        return widget
          ? widget.widgetCategoryID !== 1
            ? widget
            : false
          : false
      }
    }
  },
  methods: {
    updateExpandedViewText (event) {
      this.widgetDetails.contentWidgets.registeredContentExpanded = event
        ? this.widgetDetails.contentWidgets.registeredContent
        : ''
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
