var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.getComponent(_vm.widgetDetails.widgetCategoryID))?[_c(_vm.getComponent(_vm.widgetDetails.widgetCategoryID).component,{tag:"component",attrs:{"widgetDetails":_vm.widgetDetails,"data":_vm.widgetDetails[_vm.getComponent(_vm.widgetDetails.widgetCategoryID).name],"chartConfigurations":_vm.widgetDetails.chartConfigurations,"widgetTblConfigurations":_vm.widgetDetails.widgetTblConfigurations,"tenantId":_vm.widgetDetails.tenantID,"formSubmitted":_vm.formSubmitted,"isEditPage":_vm.isEditPage}})]:_vm._e(),_c('b-form-row',[_c('b-col',[_c('b-form-group',{class:{
          'is-invalid':
            !_vm.widgetDetails.contentWidgets.registeredContent &&
            _vm.widgetDetails.widgetCategoryID == 1 &&
            _vm.formSubmitted,
        }},[_c('p',[_vm._v(" Dashboard text "),(_vm.widgetDetails.widgetCategoryID != 1)?_c('span',[_vm._v("(Optional)")]):_vm._e()]),_c('editor',{attrs:{"id":"description","api-key":_vm.tinyEditorOptions.apiKey,"init":_vm.tinyEditorOptions.initConfig},model:{value:(_vm.widgetDetails.contentWidgets.registeredContent),callback:function ($$v) {_vm.$set(_vm.widgetDetails.contentWidgets, "registeredContent", $$v)},expression:"widgetDetails.contentWidgets.registeredContent"}}),(
            !_vm.widgetDetails.contentWidgets.registeredContent &&
            _vm.widgetDetails.widgetCategoryID == 1 &&
            _vm.formSubmitted
          )?_c('b-form-invalid-feedback',{staticClass:"d-block"},[_vm._v("Dashboard content required.")]):_vm._e()],1)],1)],1),_c('b-form-row',[_c('b-col',[_c('b-form-group',[_c('b-form-checkbox',{attrs:{"id":"use-dashboard-text-check","name":"use-dashboard-text-check"},on:{"change":_vm.updateExpandedViewText}},[_vm._v(" Use dashboard text for expanded view ")])],1)],1)],1),_c('b-form-row',[_c('b-col',[_c('b-form-group',{class:{
          'is-invalid':
            !_vm.widgetDetails.contentWidgets.registeredContentExpanded &&
            _vm.widgetDetails.widgetCategoryID == 1 &&
            _vm.formSubmitted,
        }},[_c('p',[_vm._v(" Expanded view text "),(_vm.widgetDetails.widgetCategoryID != 1)?_c('span',[_vm._v("(Optional)")]):_vm._e()]),_c('editor',{attrs:{"api-key":_vm.tinyEditorOptions.apiKey,"init":_vm.tinyEditorOptions.initConfig},model:{value:(_vm.widgetDetails.contentWidgets.registeredContentExpanded),callback:function ($$v) {_vm.$set(_vm.widgetDetails.contentWidgets, "registeredContentExpanded", $$v)},expression:"widgetDetails.contentWidgets.registeredContentExpanded"}}),(
            !_vm.widgetDetails.contentWidgets.registeredContentExpanded &&
            _vm.widgetDetails.widgetCategoryID == 1 &&
            _vm.formSubmitted
          )?_c('b-form-invalid-feedback',{staticClass:"d-block"},[_vm._v("Expanded view content required.")]):_vm._e()],1)],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }